import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Item from "../ReviewDetails/Item";
import TableContainer from './TableContainer';
import { getSearchDetails, getMemberShipDetails } from "../../services/auth";
import './custom.css'


const Search = (props) => {
  const [searchFlag, setsearchFlag] = useState(false);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [membershipdetails, setMemberShipDetails] = useState([]);

  const [profile, setProfile] = useState([]);

  const [searchDetails, setsearchDetails] = useState({
    first_name: "", last_name: "", email: "",
    phone: "", city: "", state: "",
    reference_by: "", membership_category: "", chapter: ""});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setsearchDetails((searchDetails) => ({ ...searchDetails, [name]: value }));
  };

  useEffect(() => {
    getMemberShipDetails()
    .then((res) => {
      setMemberShipDetails(res.users);
      
    })
    .catch((e) => console.log("Error while Membership count", e));  
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setsearchFlag(true);
    getSearchDetails(searchDetails)
    .then((res) => {
      setUsers(res.users);
      setProfile(res.users.profile);
      
    })
    .catch((e) => console.log("Error while fetchReviewProfiles", e));        
  };

  const renderRowSubComponent = (row) => {
    const {
      first_name, last_name, phone, email, city, address_line_1,
      address_line_2, dis_state, zip_code, country, member_name, fee,
      payment_mode, status, chapter_name, reference_by, reference_phone
    } = row.original;    
    return (
      <div className="contact-page__form-box expand-box">
        <b>Personal Details</b>
        <div className="row">
          <div className="col-xl-6">
            <Item label="Referrer By" value={reference_by} />
          </div>
          <div className="col-xl-6">
            <Item label="Referrer Phone" value={reference_phone} />
          </div>

          <div className="col-xl-6">
            <Item label="First Name" value={first_name} />
          </div>
          <div className="col-xl-6">
            <Item label="Last Name" value={last_name} />
          </div>

          <div className="col-xl-6">
            <div className="review-details__item">
              <div className="review-details__item--label">Email:</div>
              <a href={"mailto:"+email}>{email}</a>
            </div>            
          </div>

          <div className="col-xl-6">
            <Item label="Phone" value={phone} />
          </div>
        </div>
        <div className="divider my-4" />
        <b>Address Details</b>
        <div className="row">
          <div className="col-xl-6">
            <Item label="Address Line 1" value={address_line_1} />
          </div>

          <div className="col-xl-6">
            <Item label="Address Line 2" value={address_line_2} />
          </div>

          <div className="col-xl-6">
            <Item label="City" value={city} />
          </div>

          <div className="col-xl-6">
            <Item label="State" value={dis_state} />
          </div>

          <div className="col-xl-6">
            <Item label="Zip Code" value={zip_code} />
          </div>

          <div className="col-xl-6">
            <Item label="Zip Code" value={country} />
          </div>
      </div>
      <div className="divider my-4" />
      <b>Membership Details</b>
      <div className="row">
        <div className="col-xl-4">
          <Item
            label="Category"
            value={member_name.split(" ", 1)}
          />
        </div>
        <div className="col-xl-4">
          <Item
            label="Chapter"
            value={chapter_name}
          />
        </div>      
        <div className="col-xl-4">
          <div className="review-details__item">
              <div className="review-details__item--label">Status:</div>
              <div style={{ textTransform: "capitalize" }}>{status?.split("_").join(" ")}</div>
            </div> 
        </div>           
        <div className="col-xl-4">
          <div className="review-details__item">
              <div className="review-details__item--label">Payment:</div>
              <div style={{ textTransform: "capitalize" }}>{payment_mode}</div>
            </div> 
        </div>
        <div className="col-xl-4">
          <Item label="Fee" value={"$ "+fee}/>
        </div>       
      </div>      
    </div>      
    );    
  };
  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Membership',
        accessor: 'member_name',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'State',
        accessor: 'dis_state',
      },      
      {
        Header: () => null,
        id: 'expander', // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? 'Hide Details 👇' : 'Show Details 👉'}
          </span>
        ),
      },        
    ],
    []
  );

  return (
    <div>
      <section className="contact-three">
        <div
          className="contact-three-shape"
          style={{
            backgroundImage:
              "url(assets/images/shapes/contact-three-shape.png)",
          }}
        ></div>
        

        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline search-tagline">Search Members</span>

            <h2 className="section-title__title search-title">Enter Search Criteria</h2>
            <div className="divider mb-2" />
          </div>

          <div className="contact-page__form-box">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                    <label class="form-field-label">First Name</label>
                    <input 
                      type="text" placeholder="First name" name="first_name"
                      value={searchDetails.name}
                      onChange={handleChange} 
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                    <label class="form-field-label">Last Name</label>
                    <input type="text" placeholder="Last name" name="last_name" 
                      value={searchDetails.last_name}
                      onChange={handleChange} 
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                    <label class="form-field-label">Email Address</label>
                    <input
                      type="text"
                      placeholder="Email address"
                      name="email"
                      value={searchDetails.email}
                      onChange={handleChange} 
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                    <label class="form-field-label">Phone Number</label>
                    <input type="text" placeholder="Phone number" name="phone" 
                      value={searchDetails.phone}
                      onChange={handleChange} 
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                    <label class="form-field-label">City</label>
                    <input type="text" placeholder="City" name="city" 
                       value={searchDetails.city}
                       onChange={handleChange} 
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                    <label class="form-field-label">State</label>
                    <input type="text" placeholder="State" name="state" 
                       value={searchDetails.state}
                       onChange={handleChange} 
                    />
                  </div>
                </div>                

                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                  <label class="form-field-label">Referred By</label>
                    <input
                      type="text"
                      placeholder="Referred by"
                      name="reference_by"
                      value={searchDetails.reference_by}
                      onChange={handleChange} 
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                  <label class="form-field-label">Membership Category</label>
                    <input
                      type="text"
                      placeholder="Membership category"
                      name="membership_category"
                      value={searchDetails.membership_category}
                      onChange={handleChange} 
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="contact-form__input-box">
                  <label class="form-field-label">Chapter</label>
                    <input
                      type="text"
                      placeholder="Chapter"
                      name="chapter"
                      value={searchDetails.chapter}
                      onChange={handleChange} 
                    />
                  </div>
                </div>    

                <div className="col-xl-3"></div>


                <div className="col-xl-6 search-margin-top">
                  <div className="contact-form__btn-box">
                      <button type="submit" className="thm-btn contact-form__btn search-btn-update">
                        Search
                      </button>
                    </div>
                </div>                
              </div>
            </form>
          </div>        
          </div>
          <br/>
          <div className="row">
            <div className="col-xl-2"></div>
            <div className="col-xl-8">
            {searchFlag && users.length > 0? (
              <div className="row">
              <TableContainer
                columns={columns}
                data={users}
                renderRowSubComponent={renderRowSubComponent}
              />
              </div>
            ) : (
              (searchFlag && users.length == 0)  ? (
                <div className="d-flex justify-content-center">
                  No Search results found!
                </div>
              ) : (
                ''
              )
            )}

            </div>
            <div className="col-xl-2"></div>
          </div>
    
        {/* <div className={searchFlag ? "container membership-count-search" : "container membership-count"}> 
        <div className="row">
          <div className="col-xl-6">
          <table role="table" class="table table-bordered table-hover">
          <thead>
          <tr role="row">
          <th colspan="1" role="columnheader"><div>Membership</div></th>
          <th colspan="1" role="columnheader"><div>Count</div></th>
          </tr>  
          </thead>
          <tbody>
          {membershipdetails.map((membership, index) => (
            <tr><td>{membership.name.split(" ", 1)}</td><td>{membership.total}</td></tr>    
              ))}
          </tbody>
          </table>
            </div>
        </div>
      </div> */}
      
      </section>
    </div>    
  );
};

export default Search;