import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextInput from "../../components/TextInput";
import PageHeader from "../../components/PageHeader";
import { validateResetDetails } from "./utils";
import { resetpassword } from "../../services/auth";
import './custom.css'


const ResetPassword = () => {
    const navigate = useNavigate();
    const [resetDetails, setResetDetails] = useState({ token: "", password: "", cpassword: "" });
    const [resetDetailsErrors, setResetDetailsErrors] = useState({});
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState(null);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    

    const handleResetPassword = async () => {
        updateResetDetails(searchParams.get("token"), "token");
        try {
            setResetDetailsErrors({});
            const { isValid, errors } = validateResetDetails(resetDetails);
            if (!isValid) {
                return setResetDetailsErrors(errors);
            }

            const data = await resetpassword(resetDetails);
            setStatus(data.message)
            setButtonDisabled(true);
            
        } catch (e) {
            console.log("Error while handleLogin", e);
            if (e.response?.data?.data?.error) {
              setResetDetailsErrors({ cpassword: e.response?.data?.data?.error});
            }            
        }
        
    }

    const redirectLogin = async () => {
      navigate("/login");
    };    
    const updateResetDetails = (val, key) => {
        setResetDetails({
        ...resetDetails,
        [key]: val,
      });
    };
    
  return (
    <>
      <section className="contact-three">
        <div
          className="contact-three-shape"
          style={{
            backgroundImage:
              "url(assets/images/shapes/contact-three-shape.png)",
          }}
        />

        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Reset Password</h2>
          </div>
          {status && (
              <>
              <div className="contact-page__form-box">
                <div className="contact-form__block-heading"></div>
                  <div className="row col-xl-6 m-auto">
                    <div className="col-xl-12">
                    <div class="validation-success-message">{status}</div>
                  </div>
                </div>
              </div>    
              <div className="row pt-2">
                <div className="col-xl-12">
                  <div className="contact-form__btn-box">
                    <button
                      className="thm-btn contact-form__btn"
                      onClick={redirectLogin}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>                       
              </>            
            )}           
          {!status && searchParams.get("token") && (
            <>
            <div className="contact-page__form-box">
              <div className="contact-form__block-heading"></div>
              <div className="row col-xl-6 m-auto">
                  <div className="col-xl-12">
                      <div className="contact-form__input-box">
                      <TextInput
                          type="password"
                          placeholder="Password"
                          value={resetDetails.password}
                          error={resetDetailsErrors.password}
                          onChange={(e) => updateResetDetails(e.target.value, "password")}
                          disabled={isButtonDisabled}                        
                      />
                      </div>
                  </div>
                <div className="col-xl-12">
                    <div className="contact-form__input-box">
                    <TextInput
                        type="password"
                        placeholder="Confirm Password"
                        value={resetDetails.cpassword}
                        error={resetDetailsErrors.cpassword}
                        onChange={(e) => updateResetDetails(e.target.value, "cpassword")}                          
                        disabled={isButtonDisabled}
                    />
                    </div>
                </div>
            </div>
          </div>
          
            <div className="row pt-5">
              <div className="col-xl-12">
                <div className="contact-form__btn-box">
                  <button
                    className="thm-btn contact-form__btn"
                    onClick={handleResetPassword}
                    disabled={isButtonDisabled}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>          
            </>
            )}
            {!searchParams.get("token") && (
            <div className="contact-page__form-box">
              <div className="contact-form__block-heading"></div>
                <div className="row col-xl-6 m-auto">
                  <div className="col-xl-12">
                    <div class="validation-error-message-reset">Invalid Request</div>
                </div>
              </div>
            </div>
            )}      
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
