import { useCallback, useEffect } from "react";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import AutoSelect from "../../components/AutoSelectInput";
import internationalState from "./consts";

const AddressDetails = (props) => {
  const {
    addressDetails,
    setAddressDetails,
    addressDetailsErrors,
    stateCodes,
    metroAreasOptions,
    allCountries,
    membershipCategory,
  } = props;

  const updateAddressDetails = useCallback(
    (val, key) => {
      console.log(key);
      console.log(val);
      setAddressDetails({
        ...addressDetails,
        [key]: val,
      });
    },
    [addressDetails, setAddressDetails]
  );

  useEffect(() => {
    if (membershipCategory !== "Community Membership") {
      updateAddressDetails("US", "country");
    }
  }, [membershipCategory, updateAddressDetails]);

  useEffect(() => {
    if (addressDetails.country !== "US") {
      updateAddressDetails("India", "country");
    }
  }, []);
  
  return (
    <div className="row">
      <div className="col-xl-6">
        <div className="contact-form__input-box">
          <TextInput
            className="address_line_1"
            placeholder="Address Line 1"
            value={addressDetails.address_line_1}
            error={addressDetailsErrors.address_line_1}
            onChange={(e) =>
              updateAddressDetails(e.target.value, "address_line_1")
            }
            required
          />
        </div>
      </div>

      <div className="col-xl-6">
        <div className="contact-form__input-box">
          <TextInput
            className="address_line_2"
            placeholder="Address Line 2"
            value={addressDetails.address_line_2}
            error={addressDetailsErrors.address_line_2}
            onChange={(e) =>
              updateAddressDetails(e.target.value, "address_line_2")
            }
          />
        </div>
      </div>

      <div className="col-xl-6">
        <div className="contact-form__input-box">
          <TextInput
            className="city"
            placeholder="City"
            value={addressDetails.city}
            error={addressDetailsErrors.city}
            onChange={(e) => updateAddressDetails(e.target.value, "city")}
            required
          />
        </div>
      </div>

      <div className={metroAreasOptions.length ? "col-xl-3" : "col-xl-6"}>
        <div className="contact-form__input-box">
          {(membershipCategory !== "Community Membership" ||
            addressDetails.country === "US") && (
            <SelectInput
              placeholder="State"
              value={addressDetails.state}
              error={addressDetailsErrors.state}
              onChange={(e) => {
                setAddressDetails({
                  ...addressDetails,
                  state: e.target.value,
                  state_name: e.target.value,
                  metro_area: "",
                });
              }}
              options={stateCodes}
              required
            />
          )}
          {membershipCategory === "Community Membership" &&
            addressDetails.country !== "US" && (
              <TextInput
                className="state"
                placeholder="State"
                value={addressDetails.state || addressDetails.state_name}
                error={
                  addressDetailsErrors.state || addressDetailsErrors.state_name
                }
                onChange={(e) => {
                  setAddressDetails({
                    ...addressDetails,
                    state: 0,
                    state_name: e.target.value,
                    metro_area: "",
                  });
                }}
                required
              />
            )}
        </div>
      </div>

      {metroAreasOptions.length > 0 && (
        <div className="col-xl-3">
          <div className="contact-form__input-box">
            <SelectInput
              placeholder="Metro Area"
              value={addressDetails.metro_area}
              error={addressDetailsErrors.metro_area}
              onChange={(e) =>
                updateAddressDetails(e.target.value, "metro_area")
              }
              options={metroAreasOptions}
              required
            />
          </div>
        </div>
      )}

      <div className="col-xl-6">
        <div className="contact-form__input-box">
          <TextInput
            className="zip_code"
            placeholder="Postal Code"
            value={addressDetails.zip_code}
            error={addressDetailsErrors.zip_code}
            onChange={(e) => updateAddressDetails(e.target.value, "zip_code")}
            type="number"
            required
          />
        </div>
      </div>

      <div className="col-xl-6">
        <div className="contact-form__input-box">
          {/* {membershipCategory !== "Community Member" && (
            <TextInput
              placeholder="Country"
              value={addressDetails.country}
              disabled
            />
          )} */}
          {/* <SelectInput
            placeholder="Country"
            value={addressDetails.country}
            error={addressDetailsErrors.country}
            onChange={(e) => {
              setAddressDetails({
                ...addressDetails,
                state: "",
                state_name: "",
                metro_area: "",
              });
              updateAddressDetails(e.target.value, "country");
            }}
            options={allCountries}
            required
            disabled={membershipCategory !== "Community Member"}
          /> */}

          <AutoSelect
            options={allCountries}
            placeholder="Country"
            //value={addressDetails.country}
            value={membershipCategory == "Community Membership" ? "+91" : "+1"}
            error={addressDetailsErrors.country}
            onChange={(e) => updateAddressDetails(e, "country")}
            required
            disabled={membershipCategory !== "Community Membership"}
            country={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
