const Fire = () => {
  return (
    <div>
      <section className="error-page">
        <div
          className="error-page-shape"
          style={{
            backgroundImage: "url(assets/images/shapes/error-page-shape.png)",
          }}
        ></div>

        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="text">
                <p>
                  Education on long term investment advices, stock market, life
                  insurance, term insurance etc. Objective is to build wealth
                  for our community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Fire;
