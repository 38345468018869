import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import PageHeader from "../../components/PageHeader";
import { validateForgotDetails } from "./utils";
import { forgotpassword } from "../../services/auth";
import './custom.css'


const ForgotPassword = () => {
    const [forgetDetails, setForgetDetails] = useState({ email: ""});
    const [forgetDetailsErrors, setForgetDetailsErrors] = useState({});
    const [status, setStatus] = useState(null);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const handleForgotPassword = async () => {
        try {
            setForgetDetailsErrors({});
            const { isValid, errors } = validateForgotDetails(forgetDetails);
            if (!isValid) {
                return setForgetDetailsErrors(errors);
            }
            
            const data = await forgotpassword(forgetDetails);
            setStatus(data.message)
            setButtonDisabled(true);
            
        } catch (e) {
            console.log("Error while handleLogin", e);
            if (e.response?.data?.data === 422) {
              setForgetDetailsErrors({ email: e.response?.data?.message?.error });
            }
        }

    }

    const updateForgetDetails = (val, key) => {
        setForgetDetails({
        ...forgetDetails,
        [key]: val,
      });
    };    

  return (
    <>
      <section className="contact-three">
        <div
          className="contact-three-shape"
          style={{
            backgroundImage:
              "url(assets/images/shapes/contact-three-shape.png)",
          }}
        />

        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Forgot Password</h2>
          </div>

          <div className="contact-page__form-box">
            <div className="contact-form__block-heading"></div>
            
            <div className="row col-xl-6 m-auto">
                <div className="col-xl-12">
                  {status && (
                    <div class="validation-success-message">{status}</div>
                  )} 
                  
                    <div className="contact-form__input-box">
                    <TextInput
                        type="email"
                        placeholder="Email"
                        value={forgetDetails.email}
                        error={forgetDetailsErrors.email}
                        onChange={(e) => updateForgetDetails(e.target.value, "email")}
                        disabled={isButtonDisabled}
                    />
                    </div>
                </div>
            </div>
          </div>

            <div className="row pt-5">
              <div className="col-xl-12">
                <div className="contact-form__btn-box">
                  <button
                    className="thm-btn contact-form__btn"
                    onClick={handleForgotPassword}
                    disabled={isButtonDisabled}
                  >
                    Send Reset Password Link
                  </button>
                </div>
              </div>
            </div>          

        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
