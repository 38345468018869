import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams} from "react-router-dom";
import './custom.css'

import PageHeader from "../../components/PageHeader";
import UserDetails from "./UserDetails";
import AddressDetails from "./AddressDetails";
import MembershipDetails from "./MembershipDetails";
import {
  prefillTheData,
  prepareRegisterPayload,
  prepareServerErrors,
  validateRegistrationFrom,
} from "./utils";
import {
  fetchChapterStates,
  fetchChapters,
  fetchMembershipCategories,
  getGeoInfo,
} from "../../services/masterData";
import PasswordDetails from "./PasswordDetails";
import FamilyDetails from "./FamilyDetails";
import PaymentOptions from "./PaymentOptions";
import {
  defaultAddressDetails,
  defaultFamilyDetails,
  defaultIndianOriginDetails,
  defaultMembershipDetails,
  defaultPasswordDetails,
  defaultUserDetails,
  internationalState
} from "./consts";
import {
  findProfileByEmail,
  register,
  updateMyProfile,
  getCountries,
} from "../../services/auth";
import { setUser } from "../../store";
import CustomLoader from "../../layout/CustomLoader";
import IndianOrigin from "./IndianOrigin";

const BecomeMember = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, token, isAdmin } = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const free = searchParams.get('q') == 'free' ? 'Community Membership' : '';

  const isLoggedInUser = !isAdmin && token;

  const profile = user?.profile;

  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({ ...defaultUserDetails });
  const [userDetailsErrors, setUserDetailsErrors] = useState({});

  const [addressDetails, setAddressDetails] = useState({
    ...defaultAddressDetails,
  });
  const [addressDetailsErrors, setAddressDetailsErrors] = useState({});

  const [membershipDetails, setMembershipDetails] = useState({
    ...defaultMembershipDetails,
  });
  const [membershipDetailsErrors, setMembershipDetailsErrors] = useState({});

  const [passwordDetails, setPasswordDetails] = useState({
    ...defaultPasswordDetails,
  });
  const [passwordDetailsErrors, setPasswordDetailsErrors] = useState({});

  const [familyDetails, setFamilyDetails] = useState({
    ...defaultFamilyDetails,
  });
  const [familyDetailsErrors, setFamilyDetailsErrors] = useState({});

  const [indianOriginDetails, setIndianOriginDetails] = useState({
    ...defaultIndianOriginDetails,
  });
  const [indianOriginDetailsErrors, setIndianOriginDetailsErrors] = useState(
    {}
  );

  const [paymentMode, setPaymentMode] = useState("zelle");

  const [stateCodes, setStateCodes] = useState([]);
  const [allStateCodes, setAllStateCodes] = useState([]);
  const [allMetroAreas, setAllMetroAreas] = useState([]);
  const [membershipCategories, setMembershipCategories] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [foundProfileByEmail, setFoundProfileByEmail] = useState(false);
  const [membershipCategory, setMembershipCategory] = useState(free);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [browserCountry, SetbrowserCountry] = useState([]);
  useEffect(() => {
    getGeoInfo();
    if (user && profile) {
      const formData = prefillTheData({ user, profile });
      setUserDetails(formData.userDetails);
      setAddressDetails(formData.addressDetails);
      setMembershipDetails(formData.membershipDetails);
      setPasswordDetails(formData.passwordDetails);
      setFamilyDetails(formData.familyDetails);
      setPaymentMode(formData.paymentMode);
      setIndianOriginDetails(formData.indianOriginDetails);
    }
  }, [user, profile]);

  const { metroAreasOptions } = useMemo(() => {
    const selectedStates = allStateCodes?.filter(
      (i) => i.short_name === addressDetails.state
    );

    const metroAreasOptions =
      selectedStates?.map((state) => {
        return state.metro_areas?.map((i) => ({
          value: i.id,
          label: i.name,
        }));
      }) || [];

    return { selectedStates, metroAreasOptions: metroAreasOptions.flat() };
  }, [addressDetails, allStateCodes]);

  const { chapterRepresent, selectedChapterState } = useMemo(() => {
    let chapterRepresent = "";
    let selectedChapterState = null;

    if (addressDetails.metro_area) {
      const selectedMetroArea = allMetroAreas.find(
        (i) => i.id.toString() === addressDetails.metro_area.toString()
      );

      selectedChapterState = allStateCodes.find(
        (i) =>
          i.id.toString() === selectedMetroArea?.chapter_state_id.toString()
      );
    }

    if (
      (addressDetails.state || addressDetails.state_name) &&
      !metroAreasOptions.length
    ) {
      if (addressDetails.country === "US") {
        selectedChapterState = allStateCodes.find(
          (i) => i.short_name === addressDetails.state
        );
      } else {
        selectedChapterState = addressDetails.state_name;
      }
    }

    if (addressDetails.country === "US") {
      const chapterOb = chapters.find(
        (i) => i.id.toString() === selectedChapterState?.chapter_id.toString()
      );

      chapterRepresent = chapterOb?.name || "";
    } else {
      chapterRepresent = "International";
    }

    return { chapterRepresent, selectedChapterState };
  }, [
    addressDetails,
    chapters,
    allMetroAreas,
    allStateCodes,
    metroAreasOptions,
  ]);

  const getMasterData = async () => {
    try {
      setLoading(true);
      const countryNames = await getCountries();
      const newCountries = countryNames.map((item) => ({
        value: item.code,
        label: item.country,
        code: item.phone_code,
      }));
      setAllCountries(newCountries);
      // Duplicate State codes [ "CA", "IA", "MO", "TX" ]
      const stateNames = await fetchChapterStates();
      setAllStateCodes(stateNames);
      // Get Geo Info
      const browser_country_data = await getGeoInfo();
      SetbrowserCountry(browser_country_data.country)


      if (user && profile) {
        const selectedState = stateNames.find(
          (i) => i.id.toString() === profile.state.toString()
        );
        setAddressDetails((prev) => ({
          ...prev,
          state: selectedState?.short_name,
          state_name: user.profile.state_name,
        }));
      }

      const metroAreasList = [];

      setStateCodes(
        [
          ...new Set(
            stateNames.map((i) => {
              metroAreasList.push(...(i.metro_areas || []));
              return i.short_name;
            })
          ),
        ]
          .sort()
          .map((i) => ({
            label: i,
            value: i,
          }))
      );

      setAllMetroAreas(metroAreasList);

      const membershipCategoriesData = await fetchMembershipCategories();
      setMembershipCategories(
        membershipCategoriesData.map((i) => ({
          label: i.name,
          value: i.id,
          original: i,
        }))
      );

      if (user) {
        membershipCategoriesData.forEach((item) => {
          if (user.profile.membership_category === item.id) {
            setMembershipCategory(item.name);
          }
        });
      }

      const chaptersData = await fetchChapters();
      setChapters(chaptersData);
      setLoading(false);
    } catch (e) {
      console.log("Error while getMasterData", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMasterData();
  }, []);

  useEffect(() => {
    if (hasSubmitted) {
      const { isValid, errors } = validateRegistrationFrom({
        userDetails,
        addressDetails,
        membershipDetails,
        passwordDetails,
        familyDetails,
        stateCodes,
        indianOriginDetails,
        isLoggedInUser,
      });

      if (!isValid) {
        setUserDetailsErrors(errors.userDetailsErrors);
        setAddressDetailsErrors(errors.addressDetailsErrors);
        setMembershipDetailsErrors(errors.membershipDetailsErrors);
        setPasswordDetailsErrors(errors.passwordDetailsErrors);
        setFamilyDetailsErrors(errors.familyDetailsErrors);
        setIndianOriginDetailsErrors(errors.indianOriginDetailsErrors);
      }
    }
  }, [
    addressDetails,
    familyDetails,
    hasSubmitted,
    indianOriginDetails,
    isLoggedInUser,
    membershipDetails,
    passwordDetails,
    stateCodes,
    userDetails,
  ]);

  function findFirstError(errors) {
    for (let category in errors) {
      const subErrors = errors[category];
      const keys = Object.keys(subErrors);
      for (let key of keys) {
        if (subErrors[key]) {
          return { category, key, message: subErrors[key] };
        }
      }
    }
    return null;
  }
  const handleSave = async () => {
    try {
      setLoading(true);
      console.log(free)
      setUserDetailsErrors({});
      setAddressDetailsErrors({});
      setMembershipDetailsErrors({});
      setPasswordDetailsErrors({});
      setFamilyDetailsErrors({});
      setIndianOriginDetailsErrors({});
      setHasSubmitted(true);
      const { isValid, errors } = validateRegistrationFrom({
        userDetails,
        addressDetails,
        membershipDetails,
        passwordDetails,
        familyDetails,
        stateCodes,
        indianOriginDetails,
        isLoggedInUser,
      });

      if (!isValid) {
        setUserDetailsErrors(errors.userDetailsErrors);
        setAddressDetailsErrors(errors.addressDetailsErrors);
        setMembershipDetailsErrors(errors.membershipDetailsErrors);
        setPasswordDetailsErrors(errors.passwordDetailsErrors);
        setFamilyDetailsErrors(errors.familyDetailsErrors);
        setIndianOriginDetailsErrors(errors.indianOriginDetailsErrors);
        const firstError = findFirstError(errors);
        if (firstError && firstError.message) {
          const firstErrorInput = document.querySelector(`.${firstError.key}`);
          if (firstErrorInput) {
            firstErrorInput.focus();
          }
        }

        setTimeout(() => {
          const input = document.querySelector(`.validation-error-message`);

          input?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }, 200);

        return;
      }

      const payload = prepareRegisterPayload({
        userDetails,
        addressDetails,
        membershipDetails,
        passwordDetails,
        familyDetails,
        paymentMode,
        selectedChapterState,
        indianOriginDetails,
      });

      if (user) {
        payload.id = user.id;
      }

      let response = null;

      if (isLoggedInUser) {
        delete payload.password;
        response = await updateMyProfile(payload);
      } else {
        response = await register(payload);
      }

      response && dispatch(setUser(response.user));

      if (isLoggedInUser) {
        return navigate("/my-profile");
      }

      if (response.paymentDetails?.redirect_url) {
        window.location.href = response.paymentDetails?.redirect_url;
      } else {
        navigate("/review-details");
      }
    } catch (e) {
      console.log("error while handleSave", e);
      if (e?.response?.status === 422) {
        const errors = prepareServerErrors(e.response.data.errors);
        setUserDetailsErrors(errors.userDetailsErrors);
        setAddressDetailsErrors(errors.addressDetailsErrors);
        setMembershipDetailsErrors(errors.membershipDetailsErrors);
        setPasswordDetailsErrors(errors.passwordDetailsErrors);
        setFamilyDetailsErrors(errors.familyDetailsErrors);
        const firstError = findFirstError(errors);
        if (firstError && firstError.message) {
          const firstErrorInput = document.querySelector(`.${firstError.key}`);
          if (firstErrorInput) {
            firstErrorInput.focus();
          }
        }        
      }
    } finally {
      setLoading(false);
    }
  };

  const findUserWithEmail = async (e) => {
    try {
      if (isLoggedInUser) return;

      setFoundProfileByEmail(false);
      const response = await findProfileByEmail({ email: e.target.value });

      if (response) {
        setFoundProfileByEmail(true);
        dispatch(setUser(response.user));
      }
    } catch (e) {
      console.log("Error While findUserWithEmail", e);
    }
  };

  const memberShipSelectHandler = (membership) => {
    updateMembershipDetails(membership.original.id, "membership_category");
    setMembershipCategory(membership.original.name);
  };

  const goBackHandler = () => {
    setHasSubmitted(false);
    setUserDetailsErrors({});
    setAddressDetailsErrors({});
    setMembershipDetailsErrors({});
    setPasswordDetailsErrors({});
    setFamilyDetailsErrors({});
    setIndianOriginDetailsErrors({});
    setMembershipCategory("");
    setTimeout(() => {
      const input = document.querySelector(`.container`);

      input?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 200);
  };

  const updateMembershipDetails = (val, key) => {
    setMembershipDetails({
      ...membershipDetails,
      [key]: val,
    });
  };
  console.log(browserCountry)
  return (
    <>
      {loading && <CustomLoader />}

      <PageHeader
        breadcrumb={[
          { label: "Home", link: "/" },
          { label: "Membership", link: "/" },
        ]}
        title={isLoggedInUser ? "My Profile" : "Become a Member"}
      />

      <section className="contact-three">
        <div
          className="contact-three-shape"
          style={{
            backgroundImage:
              "url(assets/images/shapes/contact-three-shape.png)",
          }}
        />

        {membershipCategory !== "" && !loading && (
          <div className="container">
            <div className="section-title text-center">
              <span className="section-title__tagline">
                {isLoggedInUser
                  ? "Please Update Your Details"
                  : "Please Share Your Details"}
              </span>

              {!isLoggedInUser && (
                <h2 className="section-title__title">Become a Member</h2>
              )}
              {!isLoggedInUser && (
                <div className="text">
                  {membershipCategories.map((item, index) => {
                    if (membershipCategory === item.original.name)
                      return (
                        <React.Fragment key={index}>
                          {item.original.description}
                        </React.Fragment>
                      );

                    return null;
                  })}
                </div>
              )}
            </div>

            {userDetails.email && foundProfileByEmail && (
              <div>
                Details Present in our database with Email: {userDetails.email}
              </div>
            )}

            <div className="contact-page__form-box">
              <div className="contact-form__block-heading">
                Personal Details
              </div>
              <UserDetails
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                userDetailsErrors={userDetailsErrors}
                findUserWithEmail={findUserWithEmail}
                membershipCategory={membershipCategory}
                addressDetails={addressDetails}
                allCountries={allCountries}
              />

              <div className="divider mb-2" />

              <div className="contact-form__block-heading">Address</div>

              <AddressDetails
                addressDetails={addressDetails}
                setAddressDetails={setAddressDetails}
                addressDetailsErrors={addressDetailsErrors}
                stateCodes={stateCodes}
                metroAreasOptions={metroAreasOptions}
                allCountries={allCountries}
                membershipCategory={membershipCategory}
              />

              <div className="divider mb-2" />

              <div className="contact-form__block-heading">
                Chapter Association
              </div>

              <div className="col-xl-12">
                <div className="contact-form__input-box">
                  <div className="contact-form__disable-text">
                    {chapterRepresent ? (
                      <>
                        You will be associated with
                        <b>
                          <span>&nbsp;</span>
                          {chapterRepresent}
                          <span>&nbsp;</span>
                        </b>
                        chapter
                      </>
                    ) : (
                      "Chapter you represent"
                    )}
                  </div>
                </div>
              </div>

              <div className="divider mb-2" />

              {!isLoggedInUser && (
                <>
                  {/* <div className="divider mb-2" /> */}
                  <div className="contact-form__block-heading">Password</div>

                  <PasswordDetails
                    passwordDetails={passwordDetails}
                    setPasswordDetails={setPasswordDetails}
                    passwordDetailsErrors={passwordDetailsErrors}
                  />
                </>
              )}

              <div className="divider mb-2" />

              {["2", "3", "4"].includes(
                membershipDetails.membership_category.toString()
              ) &&
                userDetails.marital_status === "married" && (
                  <>
                    <div className="contact-form__block-heading">
                      Family Details
                    </div>
                    <FamilyDetails
                      familyDetails={familyDetails}
                      setFamilyDetails={setFamilyDetails}
                      familyDetailsErrors={familyDetailsErrors}
                      addressDetails={addressDetails}
                      allCountries={allCountries}
                    />
                    <div className="divider mb-2" />
                  </>
                )}

              <div className="contact-form__block-heading">Indian Origin</div>

              <IndianOrigin
                indianOriginDetails={indianOriginDetails}
                setIndianOriginDetails={setIndianOriginDetails}
                indianOriginDetailsErrors={indianOriginDetailsErrors}
              />

              {membershipDetails.membership_category.toString() !== "1" &&
                membershipCategory !== "Community Membership" && (
                  <>
                    <div className="divider mb-2" />
                    <div className="contact-form__block-heading">
                      Payment Options
                    </div>

                    <PaymentOptions
                      paymentMode={paymentMode}
                      setPaymentMode={setPaymentMode}
                      disabled={isLoggedInUser}
                    />

                    {/* {paymentMode === "paypal" && <div>zelle help text</div>} */}
                  </>
                )}

              <div className="row pt-5">
                <div className="col-xl-6 d-flex justify-content-end">
                  <div className="contact-form__btn-box">
                    <button
                      className="thm-btn contact-form__btn"
                      onClick={goBackHandler}
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="col-xl-6  d-flex justify-content-start">
                  <div className="contact-form__btn-box">
                    <button
                      className="thm-btn contact-form__btn"
                      onClick={handleSave}
                    >
                      {isLoggedInUser ? "Update" : "Next"}
                    </button>
                  </div>
                </div>
              </div>

              <div className="result"></div>
            </div>
          </div>
        )}
        {membershipCategory === "" && !loading && (
          <div className="container">
            {browserCountry != 'US' &&
              <><div className="row text-dark font-weight-bold mb-4 mb-4-top">
                <div className="d-flex justify-content-start section-title__tagline">
                  International (Outside USA) Applicants
                </div>
              </div><div className="divider my-4 my-4-top" />
              {membershipCategories.map((item, index) => {
              if (item.original.name === "Community Membership")
                return (
                  <React.Fragment key={index}>
                    <div className="row d-flex align-items-center">
                      <div className="col-4 contact-form__block-heading membership_title">
                        {item.original.name}
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        {parseFloat(item.original.fee) === 0
                          ? "Free"
                          : "$" + parseFloat(item.original.fee)}
                      </div>
                      <div className="col-4">
                        <button
                          className="thm-btn thm-btn-update contact-form__btn"
                          onClick={() => {
                            memberShipSelectHandler(item);
                          }}
                        >
                          Select
                        </button>
                      </div>
                        </div>
                    
                  </React.Fragment>
                );

              return null;
            })}
            <br/>              
              </>
            }
            <div className="row text-dark font-weight-bold mb-4 mb-4-top">
              <div className="section-title__tagline d-flex justify-content-start">
                USA Applicants
              </div>
            </div>
            <div className="divider my-4 my-4-top" />
            {membershipCategories.map((item, index) => {
              if (item.original.name === "Community Membership") return null;
              return (
                <React.Fragment key={index}>
                  <div className="row d-flex align-items-center my-4">
                    <div className="col-4 contact-form__block-heading membership_title">
                      {item.original.name}
                    </div>
                    <div className="col-4 d-flex justify-content-center">
                      {parseFloat(item.original.fee) === 0
                        ? "Free"
                        : "$" + parseFloat(item.original.fee)}
                    </div>
                    <div className="col-4">
                      <button
                        className="thm-btn thm-btn-update contact-form__btn"
                        onClick={() => {
                          memberShipSelectHandler(item);
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                  {/* <div className="divider my-4" /> */}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </section>
    </>
  );
};

export default BecomeMember;
