import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const AutoSelect = (props) => {
  const { options, country } = props;
  const [singleSelections, setSingleSelections] = useState([]);

  const AutoSelectHandler = (params) => {
    setSingleSelections(params);
    if (params.length > 0) {
      if (country) {
        props.onChange(params[0].value);
      } else {
        props.onChange(params[0].code);
      }
    }
  };

  useEffect(() => {
    if (props.value) {
      const defaultValue = props.options.filter((e) => e.code === props.value);
      if (defaultValue.length > 1) {
        setSingleSelections([defaultValue[1]]);
      } else {
        setSingleSelections(defaultValue);
      }
    }
  }, []);

  return (
    <>
      <div className="form-group">
        <label className="form-field-label">
          {props.label || props.placeholder}
          {props.required && <span style={{ color: "red" }}> *</span>}
        </label>
        <div style={{ position: "relative" }}>
          <Typeahead
            id="basic-typeahead-single"
            labelKey="label"
            // onChange={setSingleSelections}
            options={options}
            placeholder={props.placeholder}
            selected={singleSelections}
            onChange={AutoSelectHandler}
            value={props.value}
            disabled={props.disabled}
          />

          <span style={{ position: "absolute", top: "15px", right: "10px" }}>
            ▼
          </span>
        </div>
      </div>
    </>
  );
};

export default AutoSelect;
