import { validateEmail } from "../../utils";

export const validateLoginDetails = (loginDetails) => {
  let isValid = true;

  const errors = {};

  if (!loginDetails.email) {
    errors.email = "Enter Email";
  } else if (!validateEmail(loginDetails.email)) {
    errors.email = "Enter Valid Email";
  }

  if (!loginDetails.password) {
    errors.password = "Enter Password";
  }

  if (Object.keys(errors).length) {
    isValid = false;
  }

  return { isValid, errors };
};

export const validateForgotDetails = (forgotDetails) => {
  let isValid = true;

  const errors = {};

  if (!forgotDetails.email) {
    errors.email = "Enter your Email";
  } else if (!validateEmail(forgotDetails.email)) {
    errors.email = "Enter Valid Email";
  }

  if (Object.keys(errors).length) {
    isValid = false;
  }

  return { isValid, errors };
};

export const validateResetDetails = (resetDetails) => {
  let isValid = true;

  const errors = {};

  if (!resetDetails.password) {
    errors.password = "Enter your password";
  }

  if (!resetDetails.cpassword) {
    errors.cpassword = "Enter the confirm password";
  }  

    if (!resetDetails.password) {
    errors.password = "Enter Password";
  } else if (resetDetails.password.length < 8) {
    errors.password = "Password Minimum 8 Characters";
  } else if (!/[A-Z]/.test(resetDetails.password)) {
    errors.password =
      "Password must have one uppercase character";
  } else if (!/[a-z]/.test(resetDetails.password)) {
    errors.password =
      "Password must have one lower character";
  } else if (!/[0-9]/.test(resetDetails.password)) {
    errors.password = "Password must have one number";
  } else if (
    !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(resetDetails.password)
  ) {
    errors.password = "Password must have one special character";
  }

  if (!resetDetails.cpassword) {
    errors.cpassword = "Enter Confirm Password";
  } else if (resetDetails.password !== resetDetails.cpassword) {
    errors.cpassword =
      "Confirm Password and Password not same";
  }

  if (Object.keys(errors).length) {
    isValid = false;
  }

  return { isValid, errors };
};
